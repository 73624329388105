export const texts = {
    pl: {
        header_primary:'Most na rzece Sava', 
        bridge_project: 'Projekt Mostu',
        council_label: 'Komitet Budowy Mostu',
        height: 'Wysokość',
        height_one: 'Jednego człowieka',
        height_two: 'Dwóch ludzi',
        height_three: 'Trzech ludzi',
        height_one_safety_desc:
            'wiosenna woda roztopowa może zerwać tak niski most',
        height_two_safety_desc: 'optymalna wysokość',
        height_three_safety_desc: 'zbyt wysoki most będzie niestabilny',
        width: 'Szerokość',
        width_one: 'Jednej owcy',
        width_two: 'Dwóch owiec',
        width_three: 'Trzech owiec',
        width_one_safety_desc: 'zaburzone proporcje, łatwo przewrócić',
        width_two_safety_desc: 'akceptowalna szerokość',
        width_three_safety_desc:
            'optymalna szerokość, zapewnia stabilność konstrukcji',
        pillars: 'Podpory',
        pillars_one: 'Jedna podpora',
        pillars_two: 'Dwie podpory',
        pillars_three: 'Trzy podpory',
        pillars_one_safety_desc: 'mała stabilność',
        pillars_two_safety_desc: 'optymalne wsparcie konstrukcji',
        pillars_three_safety_desc: 'spływająca rzeką kra może uszkodzić most',
        railings: 'Barierka',
        railings_none: 'Brak barierki',
        railings_parapet: 'Balustrada',
        railings_wall: 'Murek',
        railings_none_safety_desc: 'optymalne rozwiązanie',
        railings_parapet_safety_desc: 'akceptowalne zabezpiecznie',
        railings_wall_safety_desc:
            'murek spowoduje duży nacisk wiejącego wiatru izmniejszy stabilność mostu',
        finish: 'Wykończenie',
        finish_soil: 'Ziemia',
        finish_wooden: 'Drewno',
        finish_stone: 'Kamień',
        finish_soil_safety_desc:
            'nawierzchnia nie ma wpływu na stabilność konstrukcji',
        finish_wooden_safety_desc:
            'nawierzchnia nie ma wpływu na stabilność konstrukcji',
        finish_stone_safety_desc:
            'nawierzchnia nie ma wpływu na stabilność konstrukcji',

        leaderstatue: 'Popiersie',
        leaderstatue_true: 'Jest',
        leaderstatue_false: 'Nie ma',
        leaderstatue_safety_desc:
            'akcesoria utrudniają optymalizację konstrukcji',
        guardpost: 'Strażnica',
        guardpost_true: 'Jest',
        guardpost_false: 'Nie ma',
        guardpost_safety_desc: 'akcesoria utrudniają optymalizację konstrukcji',
        chamoisstatue: 'Kozica',
        chamoisstatue_true: 'Jest',
        chamoisstatue_false: 'Nie ma',
        chamoisstatue_safety_desc:
            'akcesoria utrudniają optymalizację konstrukcji',
        safety_points_short: 'PB',
        other: 'Inne',
        bridge_parts: 'Elementy Mostu',
        mayor_letter: 'List od sołtysa',
        your_goals: 'Twoje Cele',
        voting_title: 'Głosowanie',
        voting_results_title: 'Wyniki Głosowania',
        voting_description:
            'To jest ten moment, kiedy podnosimy ręce i naciskamy przyciski',
        voting_declaration1: 'Na mocy uprawnień nadanych mi przez Radę Wioski',
        voting_declaration2: 'niniejszy projekt mostu',
        voting_approve: 'Zatwierdzam',
        voting_reject: 'Odrzucam',
        status_success: 'Most stoi',
        status_success_2: 'Sukces!',
        status_fail: 'Porażka!',
        status_fail_2: 'Most się zawali, nie otrzymujecie punktów',
        voting_summary_points: 'Suma punktów Satysfakcji rady',
        voting_summary_safe: 'Punkty bezpieczeństwa',
        victory_points_short: 'PZ',
        victory_points: 'Punktacja',
        solution_title: 'Optymalne rozwiązanie',
        solution_description1: 'Dwie optymalne opcje - w każdej do zdobycia',
        solution_description2: 'Najkorzystaniejsze rozwiązanie - do zdobycia',
        solution_heading1: 'Opcja pierwsza',
        solution_heading2: 'Opcja druga',
        solution_alternative: 'Albo',
        solution_points1: '11',
        solution_points2: '14',
        sheep_title: 'Owcodawanie',
        sheep_title2: 'Wyniki',
        sheep_heading:
            'To czas pokazać inżynierom jak bardzo cenicie ich pracę',
        sheep_heading2: 'Jak oceniliście działania inżynierów?',
        loading_title: 'Most na Rzece Sava',
        loading_introduction:
            'Hej! Trener właśnie przygotowuje dla was grę, za kilka chwil zaczniecie rozgrywkę',
        lost_players: 'Zaloguj się ponownie do gry wybierając swoją postać',
        show_bridge: 'Zaprezentuj most radzie',
        manual_introduction:
            'Niewielka miejscowość nad Savą, wartką, górską rzeką, to wasz dom. Życie w wiosce przebiegało spokojnie, do czasu kiedy okazało się, że ziemie po waszej stronie rzeki nie wystarczają do wypasania stale rosnących stad owiec. Na wioskowej naradzie zaproponowano rozwiązanie: należy zbudować most przez rzekę, żeby dostać się do pastwisk na drugim brzegu. Po waszej stronie trawy ledwo starcza, a na wyciągnięcie ręki macie zielone połacie łagodnych zboczy, idealnych do tego, żeby zapełnić je waszą trzódką. Rada Wioski (w której zasiadasz) posłała więc do miasta po Inżyniera, który jest specjalistą od budowy mostów. Jego zadaniem będzie określić, jaki most będzie odpowiedni. Na szczęście ma was do pomocy, bo przecież taki miastowy nic nie wie o realiach życia w górach i jego projekt na pewno trzeba będzie dostosować pod wasze potrzeby. No chyba, że będzie stawiał opór...',

        mayor: {
            label: 'Sołtys',
            objective0a:
                'Cały ten pomysł z\u00A0mostem może bardzo pokrzyżować Twoje plany na\u00A0reelekcję. Wszystko wskazuje na\u00A0to, że\u00A0powinieneś wyczuć nastroje ludności i\u00A0pozornie zgadzać się z\u00A0Inżynierem i\u00A0jego propozycjami albo wprost krytykować jego poczynania, niezależnie jakie będą - liczy się Twoje poparcie w Radzie Wioski.',
            objective0b:
                'Twoim zadaniem jest zdobyć jak najwięcej Punktów Zadowolenia. Jednak pamiętaj, że nie otrzymasz żadnych, jeśli most się zawali!',
            objective1a:
                'Sąsiednia wioska już wybudowała most, więc wasz musi koniecznie być bardziej okazały.',
            objective1b:
                'Otrzymasz Punkt Zadowolenia, jeżeli most będzie wysoki na trzech ludzi.',
            objective2a:
                'W sumie to sobie myślisz, że wysokość to\u00A0nie wszystko - most szeroki na\u00A0trzy owce na pewno zrobi odpowiednie wrażenie. ',
            objective2b:
                'Otrzymasz Punkt Zadowolenia, jeśli most będzie szeroki na trzy owce.',
            objective3a:
                'Ostatnio do mody wróciły tradycyjnie rzeźbione balustrady, mieszkańcy wioski za nimi przepadają. Koniecznie trzeba takie zamontować na\u00A0moście - ludzie na pewno to docenią.',
            objective3b:
                'Otrzymasz punkt zadowolenia, jeśli most będzie miał balustradę.',
            objective4a:
                'Most to pierwsza rzecz, jaką zobaczą przybywający do\u00A0wioski, przez co powinien być reprezentacyjny. Co jest bardziej reprezentacyjnego, niż witające gości popiersie sołtysa?',
            objective4b:
                'Dostaniesz jeden punkt zadwolenia, jeżeli na moście stanie Twoje popiersie.',
        },
        brother: {
            label: 'Brat Sołtysa',
            objective0a:
                'Swoją pozycję jako brat Sołtysa wykorzystujesz w\u00A0najlepszy możliwy sposób: zajmujesz się handlem z\u00A0innymi wioskami, no i\u00A0przy okazji ogarniesz czasem jakiś mały bonus dla siebie.',
            objective0b:
                'Twoim zadaniem jest zdobyć jak najwięcej Punktów Zadowolenia. Jednak pamiętaj, że nie otrzymasz żadnych, jeśli most się zawali!',
            objective1a:
                'Ważne dla Ciebie jest to, żeby most miał trzy podpory, wtedy na pewno wozy z towarami będą bezpieczne.',
            objective1b:
                'Otrzymasz jeden Punkt Zadowolenia, jeśli most będzie miał 3 podpory.',
            objective2a:
                'Kamienne wykończenie mostu najbardziej utrudni śledzenie, kto i\u00A0kiedy podróżował po moście, co bardzo ułatwi Ci twoje przedsięwzięcia poza\u00A0wioską. ',
            objective2b:
                'Otrzymasz jeden punkt zadowolenia, jeśli most będzie miał kamienne wykończenie traktu.',
            objective3a:
                'Wozy kupieckie są czasem bardzo szerokie. Nic nie może ograniczać Twoich zysków! ',
            objective3b:
                'Most nie może mieć żadnych barierek - ani balustrada, ani\u00A0murek nie wchodzą w grę. Jeżeli tak się stanie, otrzymasz jeden punkt zadowolenia',
            objective4a:
                'Poza tym grunt to rodzina, więc powinineś wyraźnie wspierać swojego brata, Sołtysa, i\u00A0zgadzać się z jego pomysłami.',
            objective4b:
                'Otrzymasz jeden punkt zadowolenia, jeśli Twój brat otrzyma co najmniej 2 punkty zadowolenia.',
        },
        handyman: {
            label: 'Złota Rączka',
            objective0a:
                'W wiosce jesteś główną specjalistką od napraw, wszyscy mieszkańcy cię znają i szanują twoje umiejętności. Ten cały inżynier z tym jego wykształceniem nijak się ma do twojego doświadczenia.',
            objective0b:
                'Twoim zadaniem jest zdobyć jak najwięcej Punktów Zadowolenia. Jednak pamiętaj, że nie otrzymasz żadnych, jeśli most się zawali!',
            objective1a:
                'Jak każdy wykwalifikowany rzemieślnik przede wszystkim dbasz o to, żeby się nie napracować. Podpór w moście powinno być zatem jak najmniej.',
            objective1b:
                'Zyskujesz Punkt Zadowolenia, jeśli most będzie miał tylko 1 podporę.',
            objective2a:
                'Twoją ulubioną rozrywką jest łowienie ryb, a żyłka, którą masz, nie jest zbyt długa.',
            objective2b:
                'Otrzymasz Punkt Zadowolenia, jeśli most będzie wysokości jednego człowieka, żebyś mogła sobie swobodnie łowić.',
            objective3a:
                'Jeśli nawierzchnia mostu będzie wykończona ziemią, to po deszczu z\u00A0całą pewnością będą w\u00A0niej grzęzły wozy. Zgadnij, kto będzie odpowiedzialny za\u00A0wyciąganie ich z błota.',
            objective3b:
                'Otrzymasz Punkt Zadowolenia, jeśli wykończenie traktu będzie inne niż ubita ziemia (czyli kamienne lub drewniane).',
            objective4a:
                'Warto trzymać z Guślarzem. Jest w porządku; najlepiej się z\u00A0nim siedzi wieczorem przy ognisku. ',
            objective4b:
                'Otrzymasz Punkt Zadowolenia, jeśli Guślarz zdobędzie dwa lub więcej Punktów Zadowolenia.',
        },
        captain: {
            label: 'Dowódca Straży Wiejskiej',
            objective0a:
                'Bezpieczeństwo jest najważniejsze. Tu są góry - dzikie zwierzęta i\u00A0bandyci zdarzają się często, a\u00A0taka konstrukcja jak most musi brać to pod uwagę!',
            objective0b:
                'Twoim zadaniem jest zdobyć jak najwięcej Punktów Zadowolenia. Jednak pamiętaj, że nie otrzymasz żadnych, jeśli most się zawali!!',
            objective1a:
                'Most musi być łatwy do\u00A0obrony. Przede wszystkim murek to konieczność. Dzięki temu rozbójnicy nie będą mogli dosięgnąć nas strzałami z brzegu.',
            objective1b:
                'Otrzymasz jeden punkt zadowolenia, jeśli most będzie miał murek.',
            objective2a:
                'Poza tym trzeba będzie wprowadzić warty przy moście, żeby ostrzegać wioskę przed napadami i\u00A0dzikimi zwierzętami.',
            objective2b:
                'Otrzymasz jeden Punkt zadowolenia, jeśli na moście będzie strażnica.',
            objective3a:
                'Wysokość mostu to skomplikowane zagadnienie. Wrogowie mogą zaatakować na łodziach, więc zbyt wysoki most umożliwi im przepłynięcie pod nim i\u00A0utrudni obronę. Z drugiej strony, zbyt niski most ograniczy widoczność i\u00A0możliwość obserwacji wroga z\u00A0daleka. Optymalne rozwiązanie to średni most - o wysokości dwóch ludzi.',
            objective3b:
                'Otrzymasz jeden punkt zadowolenia, jeśli most będzie wysoki na dwóch ludzi.',
            objective4a:
                'Wykończenie traktu musi być drewniane, by w razie przytłaczającej przewagi atakujących móc go podpalić.',
            objective4b:
                'Za taką nawierzchnię mostu otrzymasz jeden punkt zadowolenia.',
        },
        sorcerer: {
            label: 'Guślarz',
            objective0a:
                'Twoim oficjalnym zadaniem jest troszczenie się o to, żeby w wiosce panowało zdrowie fizyczne i\u00A0duchowe. Wiadomym jest, że\u00A0wszystko co złe przychodzi z\u00A0zewnątrz.',
            objective0b:
                'Twoim zadaniem jest zdobyć jak najwięcej Punktów Zadowolenia. Jednak pamiętaj, że nie otrzymasz żadnych, jeśli most się zawali!',
            objective1a:
                'Wcale nie podoba Ci się ten pomysł z\u00A0budowaniem mostu, ale skoro już musi być, to im będzie węższy, tym mniej złych sił przedostanie się do Waszej wioski. ',
            objective1b:
                'Otrzymasz jeden Punkt Zadowolenia, jeśli most będzie miał szerokość jednej owcy.',
            objective2a:
                'Wiadomo też, że dwa to\u00A0liczba chroniąca przed złymi mocami, zatem most koniecznie powinien zostać oparty na dwóch podporach. ',
            objective2b: 'Otrzymasz za to jeden Punkt Zadowolenia.',
            objective3a:
                'Ochronisz wioskę przed złymi mocami magicznymi znakami wyrysowanymi na moście. Ważne, żeby trakt był wykończony ubitą ziemią, wtedy będzie łatwo rysować nowe znaki w\u00A0zależności od potrzeb.',
            objective3b:
                'Jeśli tak się stanie, otrzymasz jeden Punkt Zadowolenia.',
            objective4a:
                'Nie przepadasz za Dowódcą Straży Wiejskiej, który zawsze próbuje forsować swoje pomysły siłą.',
            objective4b:
                'Jeśli zdobędzie 2 lub mniej Punktów Zadowolenia, Ty dostaniesz jeden punkt dodatkowo.',
        },
        chairwoman: {
            label: 'Przewodnicząca Koła Gospodyń Wiejskich',
            objective0a:
                'Budowa tego mostu to prawdziwe błogosławieństwo - masz mnóstwo pomysłów do\u00A0czego może się przydać taka konstrukcja!',
            objective0b:
                'Twoim zadaniem jest zdobyć jak najwięcej Punktów Zadowolenia. Jednak pamiętaj, że nie otrzymasz żadnych, jeśli most się zawali!',
            objective1a:
                'Most to pierwsza rzecz, jaką zobaczą przybywający do wioski - przez co powinien być reprezentacyjny. Co jest bardziej reprezentatywnego, niż pięknie rzeźbiona figura kozicy, symbolizującej góralski upór?',
            objective1b:
                'Otrzymasz punkt zadowolenia, jeżeli przy moście stanie figura kozicy.',
            objective2a:
                'Poza tym, warto byłoby zbudować taki most, żeby można było dekorować go odpowiednimi roślinami w zależności od nadarzających się okazji. Najlepiej nada się drewniana balustrada, wtedy przypinanie kwiatów będzie proste i\u00A0wygodne, a\u00A0dekoracje będzie widać z\u00A0daleka.',
            objective2b:
                'Otrzymasz jeden punkt zadowolenia, jeśli most będzie miał balustradę.',
            objective3a:
                'Wielokrotnie zastanawiałaś się, czy dla dobra wioski ważniejszy jest mądry przywódca, czy może jednak silna straż wiejska. Wydaje się, że oba są równie ważne. Na wszelki wypadek zatem sprytnie będzie popierać Sołtysa... albo Dowódcę Straży Wiejskiej... albo obu.',
            objective3b:
                'Otrzymasz punkt zadowolenia, jeśli Sołtys i Dowódca Straży Wiejskiej zdobędą w sumie 3 lub więcej punktów zadowolenia - w dowolnym układzie.',
            objective4a:
                'Bezpieczeństwo wszystkich mieszkańców wioski jest ważne - a\u00A0zwłaszcza dzieci! Na pewno będą interesować się mostem, więc najlepsze co można zrobić, to\u00A0zbudować go jak najniższym. Wtedy łatwo będzie je wyławiać z\u00A0rzeki, gdy już (niechybnie!) do niej wpadną.',
            objective4b:
                'Otrzymasz punkt zadowolenia gdy most będzie miał wysokość jednego człowieka.',
        },
        engineer_manual: {
            title: 'Wytyczne zlecenia',
            manual1a:
                'Twoim zadaniem jest przedstawienie Radzie Wioski swojego projektu mostu, który zostanie dla nich wykonany. Ze względu na to, że most musi posiadać atest bezpieczeństwa, otrzymałeś ',
            manual1b: 'Tabelę Kryteriów Bezpieczeństwa',
            manual1c:
                ', na której znajdują się poszczególne parametry mostu, wraz z ich wpływem na jego współczynnik bezpieczeństwa.',
            manual2a:
                'Projekt mostu składa się z zestawu twoich propozycji dla każdego z kryteriów:',
            manual2b:
                ' wysokość i szerokość mostu, liczba podpór, barierki, wykończenie traktu, inne.',
            manual3a:
                'Każdy wybór konkretnej opcji modyfikuje poziom Bezpieczeństwa mostu. Niektóre wybory zwiększają Bezpieczeństwo, inne je zmniejszają (szczegóły w Tabeli Bezpieczeństwa).',
            manual3b:
                ' Żeby most był bezpieczny i się nie zawalił, musi mieć co najmniej 3 Punkty Bezpieczeństwa.',
            manual3c:
                ' Inaczej mówiąc - nie wolno Ci przedstawić projektu mostu, który będzie miał mniej niż 3 Punkty Bezpieczeństwa.',
            manual4a:
                'Nie jest istotne, za co te punkty zostaną zgromadzone - most po prostu musi mieć 3 lub więcej Punktów Bezpieczeństwa - inaczej się zawali. Nie ma żadnej premii za więcej niż 3 Punkty Bezpieczeństwa.',
            manual5a:
                'Projekt mostu, aby został przyjęty do realizacji, musi zostać przegłosowany przez Radę Wioski. Zasady głosowania w Radzie są mało liberalne - można głosować tylko ‘tak’ lub ‘nie’ i tylko jeden głos może nie zgadzać się z resztą, żeby projekt został zaakceptowany. Jeśli projekt nie zostanie zaakceptowany przez głosowanie przedstawiasz nowy, zmodyfikowany, który zostanie poddany pod kolejne głosowanie.',
            manual6a:
                'Rozpoczynasz, przedstawiając swój pierwszy projekt i poddając go pod dyskusję i ewentualne głosowanie Radzie. Na podstawie efektów dyskusji możesz zmodyfikować swój pomysł i przedstawić kolejny projekt. Twoje zadanie kończy się w momencie, w którym Rada zdecyduje się na Twoje rozwiązanie. ',
            title__points: 'Zasady punktacji',
            manual7: 'Twój wynik jest oceniany następująco:',
            manual8:
                'Każdy z Członków Rady otrzyma pewną ilość Punktów Zadowolenia w zależności od tego, jak bardzo most odpowiada jego osobistym preferencjom.',
            manual9:
                'Twój wynik to suma wszystkich Punktów Zadowolenia członków Rady. Im bardziej zadowolony jest Twój Klient (czyli cała Rada), tym lepszy masz wynik. ',
            manual10:
                'Żeby otrzymać jakiekolwiek punkty, most musi powstać i się nie zawalić (czyli musi zostać pozytywnie zaopiniowany przez Radę Wioski ORAZ mieć 3 lub więcej Punktów Bezpieczeństwa). ',
        },
        letter_mayor: {
            letter_title1: 'Wytyczne sołtysa',
            letter_title2: 'Zasady punktacji',
            letter1: 'Moi Drodzy,',
            letter2:
                'Musiałem pilnie wyjechać w istotnych sprawach wioskowych, w związku z czym nie mogę uczestniczyć w obradach dotyczących budowy mostu. Zostawiam poniżej moje przemyślenia dotyczące tego, jak ta budowla powinna wyglądać i jakie funkcje spełniać.',
            letter3:
                ' Ostrzegam od razu inżyniera-konsultanta: jeśli nie uda się Wam dojść do porozumienia, znamy sposoby na radzenie sobie z niekompetentnymi miastowymi. Masz daleko do domu i łatwo będzie ci się zgubić bez naszego przewodnictwa, prawdaż.',
            letter4: 'Z pozdrowieniami, Wasz Sołtys',
            letter5a:
                'Most ma symbolizować naszą potęgę – musi być zbudowany z rozmachem. Najlepiej jakby był wysoki na trzech ludzi. Dzięki takiej budowli z daleka będzie widać, że nasza wioska jest bogata i godna szacunku!',
            letter5b:
                'Sołtys otrzyma Punkt Zadowolenia jeżeli most będzie wysoki na trzech ludzi.',
            letter6a:
                'W sumie to myślę, że wysokość to nie wszystko: most szeroki na trzy owce na pewno zrobi odpowiednie wrażenie.',
            letter6b:
                'Sołtys otrzyma Punkt Zadowolenia jeśli most będzie szeroki na 3 owce.',
            letter7a:
                'Drewniane balustrady dodadzą uroku i bezpieczeństwa naszemu gigantowi. Guślarzu - myślę że będą odpowiednie do wieszania twoich ochronnych amuletów, mógłbym wyrazić na to zgodę, gdyby balustrady rzeczywiście powstały.',
            letter7b:
                'Sołtys otrzyma Punkt Zadowolenia jeśli będzie balustrada.',
            letter8a:
                'Myślę, że należy też wyposażyć most w coś, co spowoduje jeszcze większą radość mieszkańców. Musi być to symbol, który połączy wszystkich ponad podziałami. Najlepszym rozwiązaniem będzie moje wspaniałe popiersie: jestem najbardziej reprezentatywnym członkiem rady, więc to jedyne logiczne wyjście. Dzięki niemu nikt nie będzie miał wątpliwości, do której wioski należy ten cud architektury.',
            letter8b:
                'Sołtys otrzyma Punkt Zadowolenia jeśli na moście stanie jego Popiersie.',
            letter9a:
                'Przypominam Wam, że mimo mej nieobecności mam prawo głosu. W wypadku nieuwzględnienia moich propozycji, bądź uwzględnienia tylko jednej, zagłosuję przeciwko powstaniu mostu. ',
            letter9b:
                'Jeśli Sołtys otrzyma mniej niż 2 Punkty Zadowolenia - będzie głosował przeciwko powstaniu mostu. Jeśli zaś otrzyma 2 lub więcej - będzie głosował za.',
        },
        engineer: { label: 'Inżynier', show_bridge: 'Zaprezentuj most radzie' },
        engineersecond: { label: 'Inżynierka' },
        page_change: 'Strona',
        summon_trainer: 'PRZYWOŁAJ TRENERA',
        faq: 'Wstęp',
        voting_not_agreed: 'Rada nie przegłosowała tej wersji mostu.',
        voting_placeholder_engineer:
            'Teraz Rada Wioski głosuje, możesz się zdrzemnąć',
        sheeping_placeholder_engineer:
            'Teraz Rada przyznaje owce w nagrodę, poczekaj',
        missing_credentials: 'Błędny adres gry, skontaktuj się z trenerem',
        game_over_intro:
            'Ta gra jest zakończona. Jeśli to niedobrze, zapytaj trenera. ',
    },
    eng: {
        header_primary:'Sava River Bridge', 
        bridge_project: 'Bridge design',
        council_label: 'Bridge Construction Committee',
        height: 'Height',
        height_one: 'One man',
        height_two: 'Two people',
        height_three: 'Three people',
        height_one_safety_desc:
            'spring snow melt water can tear down such a low bridge',
        height_two_safety_desc: 'optimum height',
        height_three_safety_desc: 'too high a bridge will be unstable',
        width: 'Width',
        width_one: 'One sheep',
        width_two: 'Two sheep',
        width_three: 'Three sheep',
        width_one_safety_desc: 'distorted proportions, easy to overturn',
        width_two_safety_desc: 'acceptable width',
        width_three_safety_desc:
            'optimal width, ensures the stability of the structure',
        pillars: 'Supports',
        pillars_one: 'One support',
        pillars_two: 'Two supports',
        pillars_three: 'Three supports',
        pillars_one_safety_desc: 'low stability',
        pillars_two_safety_desc: 'optimal structure support',
        pillars_three_safety_desc:
            'ice flowing down the river can damage the bridge',
        railings: 'Railing',
        railings_none: 'No railing',
        railings_parapet: 'Balustrade',
        railings_wall: 'Wall',
        railings_none_safety_desc: 'optimal solution',
        railings_parapet_safety_desc: 'acceptable protection',
        railings_wall_safety_desc:
            'the wall will cause a high pressure of blowing wind and reduce the stability',
        finish: 'Pavement',
        finish_soil: 'Earth',
        finish_wooden: 'Wood',
        finish_stone: 'Stone',
        finish_soil_safety_desc:
            'The pavement does not affect the stability of the structure',
        finish_wooden_safety_desc:
            'The pavement does not affect the stability of the structure',
        finish_stone_safety_desc:
            'The pavement does not affect the stability of the structure',
        leaderstatue: 'Bust',
        leaderstatue_true: 'Present',
        leaderstatue_false: 'Not present',
        leaderstatue_safety_desc:
            'accessories  hinders optimal design planning',
        guardpost: 'Watchtower',
        guardpost_true: 'Present',
        guardpost_false: 'Not present',
        guardpost_safety_desc: 'accessories hinders optimal design planning',
        chamoisstatue: 'Chamois',
        chamoisstatue_true: 'Is',
        chamoisstatue_false: 'Not present',
        chamoisstatue_safety_desc:
            'accessories  hinders optimal design planning',
        safety_points_short: 'SP',
        other: 'Other',
        bridge_parts: 'Bridge elements',
        mayor_letter: 'Chief Letter',
        your_goals: 'Your Goals',
        voting_title: 'Voting',
        voting_results_title: 'Voting Results',
        voting_description:
            'This is the moment when we raise our hands and press the buttons',
        voting_declaration1:
            'By the authority granted to me by the Village Council, I',
        voting_declaration2: 'this bridge design',
        voting_approve: 'Approve',
        voting_reject: 'Reject',
        status_success: 'The bridge is standing',
        status_success_2: 'Success!',
        status_fail: 'Failure!',
        status_fail_2: "The bridge collapsed, you don't get any points",
        voting_summary_points: 'Total Village Council satisfaction points',
        voting_summary_safe: 'Safety points',
        victory_points_short: 'VP',
        safe_points_short: 'SP',
        victory_points: 'Scoring',
        solution_title: 'Optimal solution',
        solution_description1: 'Two optimum options - you can score',
        solution_description2: 'The best solution - you can score',
        solution_heading1: 'First option',
        solution_heading2: 'Second option',
        solution_alternative: 'Or',
        solution_points1: '11',
        solution_points2: '14',
        sheep_title: 'SheepGiving',
        sheep_title2: 'Results',
        sheep_heading:
            'This is the time to show engineers how much you value their work',
        sheep_heading2: 'How did you evaluate the engineers actions?',
        loading_title: 'Bridge over the Sava river',
        loading_introduction:
            'Hi! The trainer is just preparing the game for you, it will begin in a few moments',
        lost_players: 'Log back into the game by selecting your character',
        show_bridge: 'Show the bridge to the Council',
        manual_introduction:
            'This little town on the swift mountain river of Sava is your home. Life in the village was peaceful until it became apparent that the lands on your side of the river were insufficient to graze the ever-growing flocks of sheep. At the village council, the solution was proposed: build a bridge across the river to get to the pastures on the other side. There is barely enough grass on your side, and at your fingertips you have green tracts of gentle slopes, perfect for filling them with your flock. The Village Council (where you sit) sent to town for an Engineer who is a bridge construction specialist. His task will be to determine which bridge will be appropriate. Fortunately, he has you to help, because such a city-dweller knows nothing about the realities of life in the mountains, and its design will certainly have to be adapted to your needs. Well, unless he resists...',
        mayor: {
            label: 'Village Chief',
            objective0a:
                "The whole idea could derail your re-election plans. Everything shows that you should sense the mood of the population and seemingly agree with the Engineer and his proposals, or directly criticize his actions, no matter what - it's important you get Village Council support.",
            objective0b:
                "Your task is to get as many Satisfaction Points as possible. However, remember that you won't get any if the bridge collapses!",
            objective1a:
                'The neighbouring village has already built a bridge, so yours has to be bigger.',
            objective1b:
                'You will get a Satisfaction Point if the bridge is three people high.',
            objective2a:
                'After all, height is not everything: a bridge that is three sheep wide will certainly make a good impression.',
            objective2b:
                'You get a Satisfaction Point if the bridge is three sheep wide.',
            objective3a:
                'Recently, traditionally carved railings have returned to fashion, and the villagers love them. Be sure to install them on the bridge, people will surely appreciate it.',
            objective3b:
                'You will get a Satisfaction Point if there is a balustrade on the bridge.',
            objective4a:
                'The bridge is the first thing that visitors to the village will see - so it should be eye-catching. What is more representative than the bust of the village leader welcoming the guests?',
            objective4b:
                'You will get a Satisfaction Point if your bust is placed on the bridge.',
        },
        brother: {
            label: "Chief's Brother",
            objective0a:
                "You make the most of your position as the Village Chief's Brother:  you trade with other villages and occasionally grab a little bonus for yourself.",
            objective0b:
                "Your task is to get as many Satisfaction Points as possible. However, remember that you won't get any if the bridge collapses!",
            objective1a:
                'Therefore, it is important for you that there are three supports under the bridge, so that the carriages with the goods are safe for sure.',
            objective1b:
                'You will get one Satisfaction Point if there are three supports under the bridge.',
            objective2a:
                'The stone pavement of the bridge will make it most difficult to keep track of who has travelled on the bridge and when, which will make your ventures outside the village much easier.',
            objective2b:
                'You will get a Satisfaction Point if there is a stone pavement on bridge.',
            objective3a:
                'Merchant wagons are sometimes very wide. Nothing can limit your profits!',
            objective3b:
                'The bridge must not have any railings - neither a railing nor a wall is an option. If this happens, you will get one Satisfaction Point.',
            objective4a:
                'Besides, the family is the most important thing, so you should strongly support your brother, the Village Chief, and agree with his ideas.',
            objective4b:
                'You will get one Satisfaction Point if your brother gets at least two satisfaction points.',
        },
        handyman: {
            label: 'Tinkerer',
            objective0a:
                'In the village you are the main repair specialist, all the villagers know you and respect your skills. That whole engineer thing with his education is nothing compared to your experience.',
            objective0b:
                "Your task is to get as many Satisfaction Points as possible. However, remember that you won't get any if the bridge collapses!",
            objective1a:
                "Like any skilled craftsman, you make sure you don't overwork yourself. Therefore, there should be as few supports under the bridge as possible.",
            objective1b:
                'You will get one Satisfaction Point if there is only one support under the bridge.',
            objective2a:
                'Your favourite pastime is fishing, and the line that you have is not very long.',
            objective2b:
                'You get a Satisfaction Point if the bridge is one man high so you can fish freely.',
            objective3a:
                'If the surface of the bridge is finished with earth, carriages will surely get stuck in it after the rain. Guess who will be responsible for getting them out of the mud.',
            objective3b:
                'You will get a Satisfaction Point if the pavement is other than compacted earth (i.e. stone or wood).',
            objective4a:
                "It's worth sticking with the Soothsayer. He's a good guy, and he's the best to hang out with in the evenings by the fire.",
            objective4b:
                'You will get a Satisfaction Point if the Soothsayer gets two or more Satisfaction Points.',
        },
        captain: {
            label: 'Captain of the Village Guard',
            objective0a:
                'Safety is paramount. There are mountains here; wild animals and bandits are common, and when thinking about a structure such as a bridge, we must take this into consideration!',
            objective0b:
                "Your task is to get as many Satisfaction Points as possible. However, remember that you won't get any if the bridge collapses!!!",
            objective1a:
                "The bridge must be easy to defend. Above all, a wall is a must. This way the robbers won't be able to reach you with their arrows from the shore.",
            objective1b:
                'You will get a Satisfaction Point if there is a wall on the sides of the bridge.',
            objective2a:
                'In addition, guards will have to be put in at the bridge to warn the Village of raids and wild animals.',
            objective2b:
                'You will get a Satisfaction Point if there is a watchover on the bridge.',
            objective3a:
                'The height of the bridge is a very complicated issue. Enemies can attack on boats, so a bridge that is too high will allow them to pass under it and make it difficult to defend. On the other hand, a bridge that is too low will reduce visibility and the ability to observe the enemy from a distance. The optimal solution is a medium bridge - two people high.',
            objective3b:
                'You will get a Satisfaction Point if the bridge is two people high.',
            objective4a:
                'The road pavement must be wooden, so that in the event of the overwhelming advantage of the attackers, you can set it on fire.',
            objective4b:
                'You will get one Satisfaction Point for this bridge pavement.',
        },
        sorcerer: {
            label: 'Soothsayer',
            objective0a:
                'Your official job is to make sure that the village is physically and spiritually healthy. It is a well-known fact that everything bad comes from outside.',
            objective0b:
                "Your task is to get as many Satisfaction Points as possible. But remember, you won't get any if the bridge collapses!",
            objective1a:
                "You don't like the idea of building a bridge at all, but if it has to be, the narrower it is, the less evil forces will get into your village.",
            objective1b:
                'You will receive one Satisfaction Point if the bridge is one sheep wide.',
            objective2a:
                'It is also known that two is the number that protects against evil forces, so the bridge must be based on two supports.',
            objective2b:
                'If this happens, you will get one Satisfaction Point.',
            objective3a:
                'You will protect the village from evil forces with magical marks drawn on the bridge. It is important that the track is finished with compacted earth, then it will be easy to draw new signs depending on your needs.',
            objective3b:
                'If this happens, you will get one Satisfaction Point.',
            objective4a:
                'You are not fond of the Captain of the Village Guard, who always tries to push his ideas through by force.',
            objective4b:
                'If he gets two Satisfaction Points or less, you will receive one additional Satisfaction Point.',
        },
        chairwoman: {
            label: "Chairwoman of the Village Women's Union ",
            objective0a:
                "Building this bridge is a blessing - you've got plenty of ideas for what this construction can be used for!",
            objective0b:
                "Your task is to get as many Satisfaction Points as possible. But don't forget that you won't get any if the bridge collapses!",
            objective1a:
                'The bridge is the first thing that visitors to the village will see - so it should be eye-catching. What is more representative than a beautifully carved figure of a chamois, symbolizing highlander stubbornness?',
            objective1b:
                'You will get a Satisfaction Point if there is a statue of a chamois by the bridge.',
            objective2a:
                'In addition, it would be worth building a bridge that would be easy to decorate with appropriate plants depending on the needs and occasions. A wooden balustrade is best, then pinning flowers will be simple and convenient, and the decorations will be visible from a distance.',
            objective2b:
                'You will get a Satisfaction Point if there is a balustrade on the bridge.',
            objective3a:
                'You have wondered many times whether a wise leader is more important to the good of the village, or a strong village guard after all. It seems that both are equally important. So, just in case, it will be smart to support the Village Chief... or the Captain of the Village Guard... or both.',
            objective3b:
                'You get a Satisfaction Point if the Village Chief and the Captain of the Village Guard score a total of 3 or more satisfaction points - in any arrangement.',
            objective4a:
                'The safety of everyone in the village is important - especially the children! They will definitely be interested in the bridge, so the best thing you can do is to build it as low as possible. Then it will be easy to fish them out of the river when they (inevitably) fall in.',
            objective4b:
                'You will get a Satisfaction Point if the bridge is one man high.',
        },
        engineer_manual: {
            title: 'Guidelines for the assignment',
            manual1a:
                'Your task is to present to the Village Council your bridge design that will be made for them. As the bridge must be certified for safety, you have been given ',
            manual1b: 'Safety Criteria Table',
            manual1c:
                ' where you can find the individual parameters of the bridge along with their impact on its safety factor.',
            manual2a:
                'The bridge design consists of your proposals for each of the criteria ',
            manual2b:
                'bridge height and width, number of supports, railings, pavement, others.',
            manual3a:
                'The selection of a specific option modifies the Safety level of the bridge. Some choices increase Safety, others decrease it (see the Safety Table for details).',
            manual3b:
                ' For a bridge to be safe and not collapse, it must have at least 3 Safety Points.',
            manual3c:
                ' In other words, you are not allowed to submit a bridge design that has less than 3 Safety Points.',
            manual4a:
                "It doesn't matter what those points are earned for - the bridge just needs 3 or more Safety Points - otherwise it will collapse. There is no bonus for more than 3 Safety Points.",
            manual5a:
                "In order to be approved for implementation, the bridge project must be approved by the Village Council. The rules of voting in the Council are not very liberal - you can only vote 'yes' or 'no' and only one vote may disagree with the rest for the project to be approved. If the project is not approved by the vote, you submit a new, modified and put to the next vote.",
            manual6a:
                'You start by presenting your first draft and submitting it to the Council for discussion and a possible vote. Based on the results of the discussion, you can modify your idea and present another draft. Your task is completed when the Council decides on your solution.',
            title__points: 'Scoring rules',
            manual7: 'Your score is rated as follows',
            manual8:
                'Each member of the Council will receive a certain number of Satisfaction Points depending on how much the bridge suits his personal preferences.',
            manual9:
                'Your score is the sum of all the Satisfaction Points of the Council Members. The more satisfied your customer (i.e. the whole Council) is, the better your score.',
            manual10:
                'In order to receive any points, the bridge has to be built and not collapse (i.e. has a positive opinion from the Village Council AND has 3 or more Safety Points)',
        },
        letter_mayor: {
            letter_title1: 'Guidelines from mayor',
            letter_title2: 'Scoring rules',
            letter1: 'My Dears,',
            letter2:
                'I had to leave urgently on important village business, and as a result I am unable to participate in the deliberations regarding the construction of the bridge. Below, I leave my thoughts on what this bridge should look like and what functions it should fulfill.',
            letter3:
                'Let me warn the consulting engineer right away: if you cannot come to an agreement, we know ways to deal with incompetent townspeople. You are a long way from home and it will be easy for you to get lost without our guidance.',
            letter4: 'Greetings, Your Village Chief',
            letter5a:
                'The bridge is meant to symbolize our power - it must be built on a grand scale. Ideally, it should be three people tall. Thanks to such a structure, it will be visible from afar that our village is rich and worthy of respect!',
            letter5b:
                'The Village Chief will get a Satisfaction Point if the bridge is three people high.',
            letter6a:
                'Height is not everything: a bridge that is three sheep wide will certainly make a good impression.',
            letter6b:
                'The Village Chief will get a Satisfaction Point if the bridge is three sheep wide.',
            letter7a:
                'Wooden railings will add charm and safety to our giant. Soothsayer, I think they will be suitable for hanging your protective amulets, I could have consented if the railings were indeed built.',
            letter7b:
                'The Village Chief will get a Satisfaction Point if there is a balustrade.',
            letter8a:
                "I think that the bridge should also be equipped with something that will bring even more joy to the inhabitants. It needs to be a symbol that unites everyone beyond divisions. The best solution would be my gorgeous bust: I'm the most representative of the council, so that's the only logical option. With it, no one will be in any doubt as to which village this architectural marvel belongs to.",
            letter8b:
                'The Village Chief will get a Satisfaction Point if his bust is on the bridge.',
            letter9a:
                'I remind you that despite my absence, I have the right to vote. If my proposals are not accepted, or only one is taken, I will vote against the construction of the bridge.',
            letter9b:
                'If the Village Chief receives less than 2 Satisfaction Point - he will vote against the bridge. If he gets 2 or more, he will vote in favor.',
        },
        engineer: {
            label: 'Engineer',
            show_bridge: 'Present the bridge design to the Council',
        },
        engineersecond: { label: 'Engineer' },
        summon_trainer: 'SUMMON THE TRAINER',
        page_change: 'Page ',
        faq: 'Intro',
        voting_placeholder_engineer:
            'Village Council is voting now. You can take a nap.',
        voting_not_agreed:
            "The Council didn't manage to agree on bridge version",
        missing_credentials: 'Wrong game URL, ask your facilitator',
        sheeping_placeholder_engineer:
            'Now, the Council Members assign sheeps as reward for your work, please wait',
        game_over_intro:
            'This game is finished. If you think it should not be, contact your facilitator. ',
    },
};
